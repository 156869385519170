
import {
  IItemUpdate,
  ILabelCreate,
  IRecommendationUpdate,
  ITargetLabel,
  IUserLabels,
  IFilterPerDataset,
} from "@/interfaces";
import {
  dispatchAddItemLabels,
  dispatchCreateLabel,
  dispatchDeleteLabel,
  dispatchGetCorrectionRecommendation,
  dispatchGetLastItem,
  dispatchGetMetrics,
  dispatchGetModels,
  dispatchGetPredictions,
  dispatchGetRecommendation,
  dispatchGetSearchRecommendation,
  dispatchGetValidationRecommendation,
  dispatchUpdateLabel,
  dispatchUpdateModel,
} from "@/store/model/actions";
import {
  readAccuracy,
  readFirstLabelContainer,
  readItems,
  readLabel,
  readLabels,
  readMetrics,
  readModel,
  readModels,
  readPrediction,
  readPredictionsByIds,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readTotalPredictions,
} from "@/store/model/getters";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import { api } from "@/api";

import { ILabelContainerUpdate } from "@/interfaces";
import { readHasAdminAccess, readToken, readUserProfile } from "@/store/main/getters";
import { dispatchUpdateLabelContainer } from "@/store/model/actions";
import { dispatchGetDatasets } from "@/store/dataset/actions";
import { dispatchGetConnectedDatasets } from "@/store/model/actions";
import { readConnectedDatasets } from "@/store/model/getters";

import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ItemPreview from "@/components/ItemPreview.vue";
import ItemPreviewFullConv from "@/components/ItemPreviewFullConv.vue";
import ItemPreviewMultiLabel from "@/components/ItemPreviewMultiLabel.vue";
import ItemPreviewContext from "@/components/ItemPreviewContext.vue";
import LabelBar from "@/components/LabelBar.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import Particle from "@/components/Particle.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import TextEditor from "@/components/TextEditor.vue";
import PromptTuningView from "./PromptTuningView.vue";
import { debounce } from "lodash";
import {
  IColumnInformation,
  IModelCreate,
  IModelFilterLabel,
  IModelFilterString,
  IModelFilterTimestamp,
} from "@/interfaces";
import { timeStamp } from "console";

// Add these interfaces
interface TimelineItem {
  message: string;
  role: 'caller' | 'agent';
  isSource: boolean;
  sourceNum: number | null;
  showGap?: boolean;
  gapSize?: number;
}

@Component({
  components: {
    ItemPreviewMultiLabel,
    ItemPreview,
    ItemPreviewFullConv,
    ItemPreviewContext,
    ModelProgress,
    LabelBar,
    Particle,
    ConfusionMatrix,
    ToolbarButton,
    RoundProgress,
    TextEditor,
  },
})
export default class LabelingView extends Vue {
  public tunePromptDialog: boolean = false;
  public id: number = 1;
  public error: string = "";
  public showMetric: boolean = false;
  public metricThreshold: number = 0.0; 
  public dialogLabel: boolean = false;
  public dialogLabelContainer: boolean = false;
  public labelName: string = "";
  public labelExamples: string[] = [];
  public showLabelWarning: boolean = false;
  public labelContainerName: string = "";
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeItemId: string = "";
  public totalClicks: number = 0;
  public startTime: any = null;
  public activeLabelExample: string = "";
  public preview: boolean = false;
  public startLabel: boolean = true;
  public loading: boolean = false;
  public redoing: boolean = false;
  public showplease: boolean = true;
  public loadingItem: boolean = false;
  public lastItem: any = { plain_text: "" };
  public loadingColor: boolean = false;

  public conversationSummary: string = "";
  public conversationSummaryError: any = null;

  public conversationReasoning: string = "";
  public conversationReasoningError: any = null;

  // MULTILABEL
  public multiLabelList: number[] = [0];
  public now: number = new Date().getTime();
  public dialogSearch: boolean = false;
  public searchPhrase: string = "";
  public timerId: any = null;
  public languageList: any = [];
  public chosenLanguage: string = "";
  public showContext: boolean = false;

  public searchFocus: boolean = true;
  public active: number = 0;
  public showSearchPhrase: string = "";

  public showAnimation: boolean = false;
  public animationStartTime: any = 0;
  public animationNow: number = 0;
  public intervalId: any = null;
  public intervalId2: any = null;
  public targetItems: IItemUpdate[] = [];
  public modelChoiceDialog: boolean = false;
  public nextPageLoading: boolean = false;

  public tempLabels: any = [];
  public taskDescription: any = [];
  public activeExampleTab: number[] = [];
  public removeIndex: number = -1;

  public nisseQuotes: string[] = [
    "I'm searching for some good examples!",
    "holy guacamole, I'm finding interesting things!",
    "Just give me a little more time",
    "Dont mind me, I'm browsing",
    "My magnifier is awesome",
    "There is a lot to read through!",
  ];
  public nisseQuote: string = "Hello there!";

  public nisseTrainingQuotes: string[] = [
    "I just Elfed up!",
    "Im getting stronger!",
    "Wow, I felt that! More please",
    "Thanks for the data. I love data!",
  ];
  public nisseTrainingQuote: string = "I'm getting stronger";
  public timedClose: boolean = true;
  public closeMenu: boolean = false;
  public dialogRemove: boolean = false;

  public showFilterMenu: boolean = false;

  public loadingResponses: boolean = false;
  public responses: any = [];
  public datasetItems: any = [];
  public previewDialog: boolean = false;
  public labelsToRemove: any = [];

  public loadingReasoning: boolean = false;
  public finalLabel: string = "";

  public color: string = "green";
  public colors: string[] = [
    "red",
    "purple",
    "pink",
    "deep-purple",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
    "black",
    "white",
  ];

  @Ref("xpbar") public readonly xp!: Element;

  public datasetFilters: any = [];
  public metadataOptions: any[] = [];
  public filterTypeDataset: string = "must";
  public columnValues: any = {};
  public pickedColumnValues: any[] = [];
  public modelFilters: any[] = [];
  public systemColumns: any = {};
  public selectedFilterColumn: string = "";
  public connectedModels: any[] = [];
  public loadingColumnValues: boolean = false;
  public searchInput: string = "";
  public startDate: string = "";
  public endDate: string = "";
  private debouncedOnSearchInput = debounce(this.performSearch, 300);
  public expandedRowDatasetFilter: number | null = null;
  public pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
  public expandedRowModelFilter: number | null = null;
  public menuEnd: boolean = false;
  public menuStart: boolean = false;
  public redirectedTo: string = "";

  public hoveredSourceNumbers: string = '';
  public hoveredSourceText: string = '';
  public isHovering: boolean = false;

  public hoverPosition = {
    x: 0,
    y: 0
  };

  public headersFilter = [
    { text: "Column", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  public headersModelFilter = [
    { text: "Model", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  public checkAllowedDateStart(date) {
    if (this.endDate) {
      const endDateObj: Date = new Date(this.endDate);
      const currentDate: Date = new Date(date);
      if (endDateObj >= currentDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public clickedConversation(itemId: string) {
    this.redirectedTo = itemId;
  }

  get maxAndMinDate() {
    let minDate: any = "";
    let maxDate: any = "";
    // console.log(this.columnInformation.column_values);
    if (this.columnValues.column_values.length > 0) {
      minDate = new Date(
        Math.min(...this.columnValues.column_values.map((date) => new Date(date).getTime())),
      )
        .toISOString()
        .slice(0, 10);
      maxDate = new Date(
        Math.max(...this.columnValues.column_values.map((date) => new Date(date).getTime())),
      )
        .toISOString()
        .slice(0, 10);
    }
    return { minDate, maxDate };
  }

  public checkAllowedDateEnd(date) {
    // if (
    //   this.columnValues.column_values.includes(date) &&
    //   currentDate >= new Date(this.maxAndMinDate.minDate)
    // ) {
    if (this.startDate) {
      // need at least 2 months. (+1 and > = 2 months)
      const startDateObj: Date = new Date(this.startDate);
      const currentDate: Date = new Date(date);
      if (currentDate >= startDateObj) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public expandRowDatasetFilter(index) {
    if (this.expandedRowDatasetFilter === index) {
      this.expandedRowDatasetFilter = null;
    } else {
      this.expandedRowDatasetFilter = index;
    }
  }
  public deleteDatasetFilter(index: number) {
    this.datasetFilters.splice(index, 1);
    this.getNewItem();
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/label?filters=${this.encodeFilters}`,
    );
  }

  public deleteModelFilter(index: number) {
    this.modelFilters.splice(index, 1);
    this.getNewItem();
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/label?filters=${this.encodeFilters}`,
    );
  }

  public addModelFilter() {
    const filter: IModelFilterLabel = {
      filter_type: "label",
      label_id: this.pickedModelAndLabels.labels,
      inherited_from: this.pickedModelAndLabels.model,
      label_container: this.pickedModelAndLabels.label_container,
    };

    this.modelFilters.push(filter);

    this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
    this.getNewItem();
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/label?filters=${this.encodeFilters}`,
    );
  }

  public getLabelsById(ids: number[], modelId: number) {
    const modelo = this.connectedModels.find((m) => m.id === modelId);
    if (modelo) {
      return modelo.label_containers[0].labels.filter((l) => ids.includes(l.id));
    } else {
      return [];
    }
  }

  public pickModelAndLabel(labelId: number, modelId: number, labelContainerId: number) {
    this.pickedModelAndLabels.model = modelId;
    this.pickedModelAndLabels.labels = [labelId];
    this.pickedModelAndLabels.label_container = labelContainerId;
  }

  public getModelById(id: number) {
    return this.connectedModels.find((m) => m.id === id);
  }

  public expandRowModelFilter(index) {
    if (this.expandedRowModelFilter === index) {
      this.expandedRowModelFilter = null;
    } else {
      this.expandedRowModelFilter = index;
    }
    // this.search(true);
  }

  public async getDatasetConnectedModels() {
    await api
      .getDatasetConnectedModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.dataset.id,
      )
      .then((r) => {
        this.connectedModels = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public getFilterTypeTextify(filterType: string) {
    if (filterType === "must") {
      return "Must contain";
    } else if (filterType === "must_not") {
      return "Must not contain";
    } else if (filterType === "timestamp") {
      return "Date filter";
    } else if (filterType === "label") {
      return "Label filter";
    } else {
      return "Unknown";
    }
  }
  public getFilterColumnName(id) {
    let mergedObject = { ...this.dataset!.meta_data.columns, ...this.systemColumns.columns };

    try {
      return mergedObject[id].name;
    } catch (error) {
      return "None";
    }
  }
  @Watch("searchInput")
  public onSearchInput(searchQuery: string) {
    // Use the debounced version of performSearch when search input changes
    this.debouncedOnSearchInput(searchQuery);
  }

  public async performSearch(searchQuery: string) {
    this.loadingColumnValues = true;
    if (this.columnValues.column_id !== undefined) {
      const allFilters = [...this.datasetFilters, ...this.modelFilters];
      await api
        .filterColumnValues(
          this.token,
          parseInt(this.$router.currentRoute.params.workspaceid, 10),
          this.dataset!.id, //FEL
          this.columnValues.column_id, // should always exist
          allFilters, // should have filters here
          searchQuery,
        )
        .then((r) => {
          this.columnValues = r.data;
          this.loadingColumnValues = false;
        })
        .catch((error) => {
          this.columnValues = {};
          this.loadingColumnValues = false;
          console.log("filterError", error.response);
        });
    }
  }
  // Dataset columns
  public async getSystemColumns() {
    await api
      .getSystemColumns(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.systemColumns = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public addDatasetFilter() {
    if (this.filterTypeDataset !== "timestamp") {
      const filter: IModelFilterString = {
        column_id: this.columnValues.column_id,
        store_column_name: this.columnValues.store_column_name,
        filter_values: this.pickedColumnValues,
        dtype: this.columnValues.dtype,
        filter_type: this.filterTypeDataset,
      };

      this.datasetFilters.push(filter);
    } else {
      const filter: IModelFilterTimestamp = {
        column_id: this.columnValues.column_id,
        store_column_name: this.columnValues.store_column_name,
        dtype: this.columnValues.dtype,
        filter_type: this.filterTypeDataset,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      this.datasetFilters.push(filter);
      this.endDate = "";
      this.startDate = "";
    }

    this.selectedFilterColumn = "";
    this.columnValues = {};
    this.pickedColumnValues = [];
    // this.getNewItem();
    this.getNewItem();
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/label?filters=${this.encodeFilters}`,
    );
  }

  public async onSelectionChangeDatasetFilter(value: any) {
    // Handle the selection change if needed

    if (value.dtype === "timestamp") {
      this.filterTypeDataset = "timestamp";
    } else {
      this.filterTypeDataset = "must";
    }
    // collect the id of the column
    const columnId: number = this.getColumnIdFromColumnName(value.name);
    this.pickedColumnValues = [];
    const allFilters = [...this.datasetFilters, ...this.modelFilters];
    // get data from backend
    await api
      .filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.dataset!.id, //FEL
        columnId,
        allFilters,
      )
      .then((r) => {
        this.columnValues = r.data;
      })
      .catch((error) => {
        this.columnValues = {};
        console.log("filterError", error.response);
      });

    if (this.$refs.autocomplete && "cachedItems" in this.$refs.autocomplete) {
      this.$refs.autocomplete!.cachedItems = [];
    }
  }

  public getColumnIdFromColumnName(name: string) {
    const datasetKeys = Object.keys(this.dataset!.meta_data.columns);
    const systemKeys = Object.keys(this.systemColumns.columns);

    const keys = [...systemKeys, ...datasetKeys];
    for (const key of keys) {
      if (
        (datasetKeys.includes(key) && this.dataset!.meta_data.columns[key].name === name) ||
        (systemKeys.includes(key) && this.systemColumns.columns[key].name === name)
      ) {
        return parseInt(key, 10);
      }
    }
    return 0; // Return 0 if no matching key is found
  }

  public createDatasetColumns() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      this.metadataOptions = [];
      Object.keys(this.dataset.meta_data.columns).forEach((columnKey) => {
        this.metadataOptions.push({
          key: columnKey,
          ...this.dataset!.meta_data.columns[columnKey],
        });
      });

      // this.metadataOptions = Object.values(this.dataset.meta_data.columns)
      //   .filter(column => (column.special_column !== 'text' && column.name !== 'UniqueID'));
      // const systemColumns = Object.values(this.systemColumns.columns);
      Object.keys(this.systemColumns.columns).forEach((columnKey) => {
        this.metadataOptions.push({
          key: columnKey,
          ...this.systemColumns.columns[columnKey],
        });
      });
    } else {
      this.metadataOptions = [];
    }
    // this.selectedMetadata = this.metadataOptions.filter((column) => column.name === "sub_cluster");
  }

  // OoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOo

  // @Watch("loadingItem")
  // public onPropertyChanged(value: boolean, oldValue: boolean) {
  //   console.log("loadingItem", value, oldValue);

  //   // Update the quotes
  //   this.nisseQuote = this.nisseQuotes[Math.floor(Math.random() * this.nisseQuotes.length)];
  //   this.nisseTrainingQuote = this.nisseTrainingQuotes[Math.floor(Math.random() * this.nisseTrainingQuotes.length)];

  //   if (value === true) {
  //     // When loadingItem turns true

  //     // Update animationStartTime to current time
  //     this.animationStartTime = Date.now();

  //     // Initialize animationNow to animationStartTime
  //     this.animationNow = this.animationStartTime;

  //     // Clear any existing interval to prevent duplicates
  //     if (this.intervalId !== null) {
  //       window.clearInterval(this.intervalId);
  //       this.intervalId = null;
  //     }

  //     // Start interval to update animationNow using window.setInterval
  //     this.intervalId = window.setInterval(() => {
  //       this.animationNow = Date.now();
  //     }, 1000); // Update every 1 second
  //   } else if (value === false) {
  //     // When loadingItem turns false

  //     // Clear the interval using window.clearInterval
  //     if (this.intervalId !== null) {
  //       window.clearInterval(this.intervalId);
  //       this.intervalId = null;
  //     }

  //     // Do not reset animationNow or animationStartTime
  //     // This prevents the counter from restarting
  //   }
  // }

  @Watch("loadingItem")
  public onPropertyChanged(value: boolean, oldValue: boolean) {
    this.nisseQuote = this.nisseQuotes[Math.floor(Math.random() * this.nisseQuotes.length)];
    this.nisseTrainingQuote =
      this.nisseTrainingQuotes[Math.floor(Math.random() * this.nisseQuotes.length)];

    if (value === true) {
      this.animationStartTime = new Date().getTime();
      this.intervalId = setInterval(() => (this.animationNow = new Date().getTime()), 1000);
    }
    if (oldValue === true) {
      this.animationNow = 0;
      clearInterval(this.intervalId);
    }
  }

  public async getValidationAccuracy() {
    const metrics = await api.createValidationMetrics(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),
      parseInt(this.$router.currentRoute.params.id, 10),
    );
  }

  public async deleteLabel() {
    for (let index = 0; index < this.labelsToRemove.length; index++) {
      this.loading = true;

      await dispatchDeleteLabel(this.$store, {
        labelContainerId: this.firstLabelContainer!.id,
        labelId: this.labelsToRemove[index].id,
      })
        .then((r) => {
          this.loading = false;
          this.dialogRemove = false;
        })
        .catch((error) => {
          this.dialogRemove = false;
          this.loading = false;
          console.error("Error deleting label", error);
        });
    }
    this.labelsToRemove = [];
  }

  public async updateModelTaskDescription() {
    if (!this.model) {
      return;
    }
    const model: IModelCreate = {
      task_description: this.taskDescription,
      name: this.model?.name,
      description: this.model?.description,
    };
    await dispatchUpdateModel(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: this.model!.id,
      model,
    })
      .then((r) => {})
      .catch((err) => {
        console.error("Error updating model task description", err);
      });
  }

  public async createLabelAPI() {
    // first update/create the new labels, then delete the ones that should be deleted.

    const labelsChanged = JSON.stringify(this.tempLabels) !== JSON.stringify(this.labels);
    const taskDescriptionChanged = this.model?.task_description !== this.taskDescription;

    if (!labelsChanged && !taskDescriptionChanged) {
      console.log(
        "No changes detected. Skipping label creation, deletion, and model task description update.",
      );
      this.tunePromptDialog = false;
      return;
    }

    if (labelsChanged) {
      for (let index: number = 0, len: number = this.tempLabels.length; index < len; index++) {
        const newLabel: ILabelCreate = {
          name: this.tempLabels[index].name,
          description: this.tempLabels[index].description,
          color: this.tempLabels[index].color,
          examples: this.tempLabels[index].examples,
        };
        this.loading = true;
        if (this.tempLabels[index].id === undefined) {
          await dispatchCreateLabel(this.$store, {
            labelContainerId: this.firstLabelContainer!.id,
            label: newLabel,
          })
            .then((r) => {})
            .catch((error) => {
              console.error("Error creating label", error);
            });
        } else {
          await dispatchUpdateLabel(this.$store, {
            labelContainerId: this.firstLabelContainer!.id,
            labelId: this.tempLabels[index].id,
            label: newLabel,
          })
            .then((r) => {
              console.log(
                "this is what i have updated",
                newLabel,
                "with label id",
                this.tempLabels[index].id,
              );
            })
            .catch((error) => {
              console.error("Error updating label", error);
            });
        }
      }

      await this.deleteLabel();
    }

    if (taskDescriptionChanged) {
      await this.updateModelTaskDescription();
    }

    await this.getValidationAccuracy();

    this.tunePromptDialog = false;
    this.loading = false;
  }

  public async runZeroShot() {
    await api.startZeroShotTraining(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),
      parseInt(this.$router.currentRoute.params.id, 10),
    );
  }

  public tunePrompt() {
    this.tempLabels = JSON.parse(JSON.stringify(this.labels));
    this.tempLabels.forEach((item) => {
      if (item.examples === null) {
        item.examples = [""];
      }
    });
    this.tempLabels.forEach((item) => this.activeExampleTab.push(0));
    this.taskDescription = this.model?.task_description;

    this.tunePromptDialog = true;
  }

  get labelsNotOk() {
    const uniqueNames = new Set(this.tempLabels.map((label) => label.name));
    return (
      this.tempLabels.length < 2 ||
      !this.tempLabels.every(
        (label) =>
          label.name !== "" &&
          label.description !== "" &&
          label.color !== null &&
          this.labelColorOk,
      ) ||
      uniqueNames.size !== this.tempLabels.length
    );
  }

  get labelsNotOkReason() {
    const errors = new Set<string>();

    if (this.tempLabels.length < 2) {
      errors.add("At least two labels are required");
    }

    if (this.taskDescription === "") {
      errors.add("You have to provide a task description");
    }

    for (const label of this.tempLabels) {
      if (label.name === "") {
        errors.add("All labels must have a name");
      }
      if (label.description === "") {
        errors.add("All labels must have a description");
      }
      if (label.color === null) {
        errors.add("All labels must have a color");
      }
    }

    return Array.from(errors);
  }

  get labelColorOk() {
    // check if there are no duplicates in the color property
    const colors = this.tempLabels.map((label) => label.color);
    return new Set(colors).size === colors.length;
  }
  get taskDescriptionError() {
    if (this.taskDescription === "" || this.taskDescription.length < 10) {
      return ["A description is required and must be at least 10 characters long"];
    }
  }

  public labelNameError(index) {
    if (this.tempLabels.length < 1) {
      return ["At least two labels are required"];
    }
    if (this.tempLabels[index].name === "" || this.tempLabels[index].name.length < 2) {
      return ["A label name is required and must be at least 2 characters long"];
    }
  }

  public labelDescriptionError(index) {
    if (
      this.tempLabels[index].description === "" ||
      this.tempLabels[index].description.length < 2
    ) {
      return ["A label description is required and must be at least 2 characters long"];
    }
  }

  public convertLabelsToRequestFormat(labels: any) {
    return labels.map((label) => ({
      name: label.name,
      color: label.color,
      description: label.description,
      examples: label.examples,
    }));
  }

  public labelColorMapper(label: string) {
    // find the color for the label name from the labels array
    return this.tempLabels.find((l) => l.name === label)?.color;
  }

  public sendToConversation(conversationId) {
    // open a new window routing to the conversation view
    const datasetId = this.dataset.id;
    window.open(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${datasetId}/dashboard/browse?conversation_id=${conversationId}`,
      "_blank",
    );
  }

  public logdataset() {
    console.log(this.dataset);
  }

  get dataset() {
    // TODO: remove that this only looks at one dataset

    // sort on id with lowest first
    const sortedConnectedDatasets = this.connectedDatasets.slice().sort((a, b) => a.id - b.id);
    console.log("sortedConnectedDatasets", sortedConnectedDatasets);
    return sortedConnectedDatasets[0];
  }

  get connectedDatasets() {
    const connectedDatasetsUnsorted = readConnectedDatasets(this.$store);
    return connectedDatasetsUnsorted.slice().sort((a, b) => b.id - a.id);
  }

  get allPicked() {
    let ret = false;
    this.targetItems.forEach((eachItem) => {
      if (eachItem.user_labels[0].label === null) {
        ret = true;
      }
    });
    return ret;
  }

  get xper() {
    return "xp";
  }

  get maxPredictedSingelLabel() {
    // function that returns the label with highest probability for a single label model (single recommendation)
    // get index with max prediction, take label id, return label obj!
    const index = [...this.items[0].predictions.keys()].reduce((a, b) =>
      this.items[0].predictions[a].prediction > this.items[0].predictions[b].prediction ? a : b,
    );
    return this.labelById(this.items[0].predictions[index].label)[0];
  }

  public async getCorrections() {
    this.delayCloseAlert();
    this.loadingItem = true;
    this.error = "";
    this.targetItems = [];
    this.redoing = false;
    this.loading = true;
    const allFilters = [...this.datasetFilters, ...this.modelFilters];

    const filterPerDataset: IFilterPerDataset = {
      dataset_id: this.dataset.id,
      store_id: this.dataset.store_id,
      model_filters: allFilters,
    };

    await dispatchGetCorrectionRecommendation(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      filterPerDataset: filterPerDataset,
    })
      .then(() => {
        this.loadingItem = false;
        this.loading = false;

        this.delayCloseAlert();
        this.error = "";
        this.targetItems = [];
        this.redoing = false;

        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          if (this.recommendationType !== "single" && this.firstLabelContainer) {
            filteredItems.forEach((i) => {
              this.createItemUpdate(
                this.getOrderedLabels(this.firstLabelContainer!.labels, i)[0].id,
                i.id,
                i.store_id,
              );
            });
          }
        }
        // end
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
        this.loading = false;
      });
  }

  public async getModelLanguages() {
    await api
      .getModelLanguages(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.model!.id,
      )
      .then((r) => {
        this.languageList = r.data;
      })
      .catch((error) => {
        console.log("UploadError", error.response);
      });
  }
  public activateModelDialog() {
    this.modelChoiceDialog = true;
  }

  public setModelToMulti() {
    const labelContainer: ILabelContainerUpdate = {
      name: "null",
      type: "multi",
    };
    dispatchUpdateLabelContainer(this.$store, {
      modelId: this.model!.id,
      labelContainerId: this.firstLabelContainer!.id,
      labelContainer,
    }).then((r) => {
      this.modelChoiceDialog = false;
      this.getRecommendationAndCreateBulk();
    });
  }

  // MULTILABEL

  public fillTargetsForMultiLabelBulk(itemId, storeId) {
    // This function is run when we create bulks etc. to fill the targets with [0].
    // If we want pre-filled choices, populate label with item.predictions instead.
    const label: ITargetLabel = {
      label_container: this.firstLabelContainer!.id,
      label: 0,
    };

    const item: IItemUpdate = {
      id: itemId,
      store_id: storeId,
      user_labels: [label],
    };

    this.targetItems.push(item);

    const takenIds: string[] = [];
    takenIds.push(itemId);
  }

  public createItemUpdateMultiLabel(labelId, itemId, storeId) {
    // Function for updating the targetItems when clicking buttons
    const targetItem = this.targetItems!.filter((targetItem) => targetItem.id === itemId);
    console.log(targetItem);
    // Take out the label list
    let userLabelList = targetItem[0].user_labels;

    // Make typescript happy
    if (!Array.isArray(userLabelList)) {
      userLabelList = [userLabelList];
    }

    if (!this.loading && this.firstLabelContainer) {
      if (labelId === -2) {
        const label: ITargetLabel = {
          label_container: this.firstLabelContainer!.id,
          label: -2,
        };
        userLabelList = [label];
        this.activeItemId = itemId;
      } else {
        if (userLabelList.some((entry) => entry.label === 0)) {
          // Remove the 0 label if it exists
          userLabelList = userLabelList.filter((entry) => entry.label !== 0);
        }
        if (userLabelList.some((entry) => entry.label === -2)) {
          // Remove the -2 label if it exists
          userLabelList = userLabelList.filter((entry) => entry.label !== 0);
        }
        if (userLabelList.some((entry) => entry.label === labelId)) {
          // Remove the label if it exists
          userLabelList = userLabelList.filter((entry) => entry.label !== labelId);
        } else {
          // Add the label if it doesn't exist
          const label: ITargetLabel = {
            label_container: this.firstLabelContainer!.id,
            label: labelId,
          };
          userLabelList.push(label);
        }
        if (userLabelList.length < 1) {
          const label: ITargetLabel = {
            label_container: this.firstLabelContainer!.id,
            label: 0,
          };
          userLabelList.push(label);
        }
      }
    }

    const item: IItemUpdate = {
      id: itemId,
      store_id: storeId,
      user_labels: userLabelList,
    };

    // const takenIds: string[] = [];
    // this.targetItems.forEach((i) => {
    //   takenIds.push(i.id);
    // });

    const filteredItems = this.targetItems.filter((filteringItem) => itemId !== filteringItem.id);
    this.targetItems = filteredItems;
    this.targetItems.push(item);
    this.activeItemId = "";

    console.log(this.targetItems);
  }

  public async saveMultiLabels() {
    this.lastItem = { ...this.items[0] };
    const itemCount = this.items.length + 1 - 1;

    const update: IRecommendationUpdate = {
      recommendation_type: readRecommendationType(this.$store),
      recommendation: readRecommendation(this.$store),
      target: readTarget(this.$store),
      items: this.targetItems,
    };
    this.loadingItem = true;
    this.loading = true;
    this.targetItems = [];
    this.pop();
    await dispatchAddItemLabels(this.$store, {
      modelId: this.model!.id,
      labels: update,
    }).then(
      (r) => {
        this.loadingItem = false;
        if (this.startTime === null) {
          this.startTime = new Date().getTime();
          setInterval(() => (this.now = new Date().getTime()), 1000);
        } else {
          this.totalClicks = this.totalClicks + itemCount;
        }
        this.redoing = false;
        if (this.recommendation === "plain_text_search") {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.getSearchRecommendationAndCreateBulk();
          }, 1000);
        } else {
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.getRecommendationAndCreateBulk();
        }
        dispatchGetPredictions(this.$store, {
          modelId: parseInt(this.$router.currentRoute.params.id, 10),
          threshold: this.metricThreshold,
        });
        this.loading = false;
      },
      (reason) => {
        console.log(reason);
      },
    );
  }

  // MULTILABEL

  public saveItemUpdate(labelId, itemId, storeId) {
    if (labelId === 0 && itemId === 0) {
      this.modelChoiceDialog = true;
      return;
    }

    if (!this.loading && this.firstLabelContainer) {
      const label: ITargetLabel = {
        label_container: this.firstLabelContainer!.id,
        label: labelId,
      };

      const item: IItemUpdate = {
        id: itemId,
        store_id: storeId,
        user_labels: [label],
        predicted_label: this.finalLabel ? this.finalLabel : undefined,
      };
      this.targetItems.push(item);
      this.saveLabels();
    }
  }
  public delayCloseAlert() {
    this.timedClose = true;
  }
  public createItemUpdate(labelId, itemId, storeId) {
    if (labelId === 0 && itemId === 0) {
      this.modelChoiceDialog = true;
      return;
    }

    const label: ITargetLabel = {
      label_container: this.firstLabelContainer!.id,
      label: labelId,
    };

    const item: IItemUpdate = {
      id: itemId,
      store_id: storeId,
      user_labels: [label],
      predicted_label: this.finalLabel ? this.finalLabel : undefined,
    };

    const takenIds: string[] = [];
    this.targetItems.forEach((i) => {
      takenIds.push(i.id);
    });

    const filteredItems = this.targetItems.filter((filteringItem) => itemId !== filteringItem.id);
    this.targetItems = filteredItems;
    this.targetItems.push(item);
    this.activeItemId = "";
  }

  get takenIds() {
    const takenIds: string[] = [];
    this.targetItems.forEach((i) => {
      takenIds.push(i.id);
    });
    return takenIds;
  }

  public saveAll() {
    const takenIds: string[] = [];
    this.targetItems.forEach((i) => {
      takenIds.push(i.id);
    });
    const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));

    filteredItems.forEach((i) => {
      this.createItemUpdate(i.predictions[0].label, i.id, i.store_id);
    });
    this.saveLabels();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  public async getMetrics() {
    let labelId: number | null;
    if (this.model!.label_containers[0].type !== "multi") {
      labelId = null;
    } else {
      labelId = this.labels[0].id;
    }

    const trainMetrics: boolean =
      this.model!.task_description !== "" && this.model!.status === "ready" ? false : true;

    dispatchGetMetrics(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      threshold: this.metricThreshold,
      labelId,
      train: trainMetrics,
    });
    dispatchGetPredictions(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      threshold: this.metricThreshold,
    });
  }

  get nextTraining() {
    if (this.model!.total_labeled < 20) {
      return (this.model!.total_labeled / 20) * 100;
    }
    return ((this.model!.total_labeled % 20) / 20) * 100;
  }
  get nextTrainingFeedback() {
    if (this.recommendation === "validation") {
      if (
        this.model!.task_description &&
        this.model!.task_description !== "" &&
        this.model!.status === "ready"
      ) {
        return "You are currently in prompt tuning mode. All items will be used for validation.";
      }
      return "Testing Labelf";
    }
    if (this.model) {
      if (this.model!.label_containers[0].labels.length < 2) {
        return `You must add at least two labels before Labelf can start learning!`;
      } else if (this.model!.total_labeled < 5) {
        return `Lets start by giving Labelf ${20 - this.model!.total_labeled} samples`;
      } else if (this.model!.total_labeled < 20) {
        return `Only ${20 - this.model!.total_labeled} more to go!`;
      }
      return `In ${20 - (this.model!.total_labeled % 20)} samples Labelf will get stronger!`;
    }
    return "";
  }

  /*    ________________________________________________     */
  /*                       GETTERS                           */
  /*    ________________________________________________     */

  get isAdmin() {
    return readHasAdminAccess;
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get highlights() {
    let highlights: any = [];
    if (
      this.model &&
      this.model!.label_containers.length &&
      this.model!.label_containers[0].labels.length
    ) {
      this.model!.label_containers[0].labels.forEach((l) => {
        const words = l.name.split(" ");
        const longWords = words.filter((w) => w.length > 4);

        if (longWords.length > 0) {
          highlights = highlights.concat(longWords);
        }
      });
    }
    return highlights;
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get predictions() {
    return readPrediction(this.$store);
  }

  get targetLabel() {
    // always convert target to number (can also be number[]) e.g. [1] or 1
    if (Array.isArray(this.target!.label)) {
      return readLabel(this.$store)(+this.$router.currentRoute.params.id, this.target!.label[0]);
    } else {
      return readLabel(this.$store)(+this.$router.currentRoute.params.id, this.target!.label);
    }
  }

  get predictionsByIds() {
    const predcounts = readPredictionsByIds(this.$store);

    // check if empty (js things)
    if (Object.keys(predcounts).length !== 0) {
      return predcounts;
    }

    // create empty if not
    let res: any = {};
    this.labels!.forEach((l) => {
      res = { ...res, ...{ [l.id]: 0 } };
    });
    return res;
  }

  get totalPredictions() {
    return readTotalPredictions(this.$store);
  }

  get accuracy() {
    return readAccuracy(this.$store);
  }

  get token() {
    return readToken(this.$store);
  }

  get metrics() {
    return readMetrics(this.$store);
  }

  get models() {
    return readModels(this.$store);
  }

  get recommendation() {
    return readRecommendation(this.$store);
  }
  get recommendationType() {
    return readRecommendationType(this.$store);
  }
  get target() {
    return readTarget(this.$store);
  }

  get item() {
    return this.items[0];
  }
  get items() {
    return readItems(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get valueDeterminate() {
    return 20;
  }

  public changeColor(color) {}

  public createLabel(id) {
    this.dialogLabel = true;
    this.activeLabelContainerId = id;
  }

  public createLabelContainer() {
    this.dialogLabelContainer = true;
  }

  public formatNumber(num) {
    if (num !== undefined) {
      return num
        .toFixed(0)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return "0";
  }

  public updateLabels(containerId, labelId) {
    if (!this.loading) {
      const label: IUserLabels = {
        label_container: containerId,
        label: labelId,
      };
      this.saveLabels();
    }
  }
  public get clicksPerSecond() {
    const mSecondsPassed = this.now - this.startTime;
    return (this.totalClicks / mSecondsPassed) * (1000 * 60);
  }

  public async saveLabels() {
    this.lastItem = { ...this.items[0] };

    const itemCount = this.items.length + 1 - 1;


    const update: IRecommendationUpdate = {
      recommendation_type: readRecommendationType(this.$store),
      recommendation: readRecommendation(this.$store),
      target: readTarget(this.$store),
      items: this.targetItems,
    };
    this.loading = true;
    this.targetItems = [];
    this.pop();
    await dispatchAddItemLabels(this.$store, {
      modelId: this.model!.id,
      labels: update,
    }).then(
      (r) => {
        this.conversationSummary = "";
        this.conversationReasoning = "";
        if (this.startTime === null) {
          this.startTime = new Date().getTime();
          setInterval(() => (this.now = new Date().getTime()), 1000);
        } else {
          this.totalClicks = this.totalClicks + itemCount;
        }
        this.redoing = false;
        if (this.recommendation === "plain_text_search") {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.getSearchRecommendationAndCreateBulk();
          }, 1000);
        } else {
          this.getRecommendationAndCreateBulk();

          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        dispatchGetPredictions(this.$store, {
          modelId: parseInt(this.$router.currentRoute.params.id, 10),
          threshold: this.metricThreshold,
        });
        this.loading = false;
      },
      (reason) => {
        this.loading = false;
      },
    );
  }

  public async getNewItem() {
    this.redoing = false;
    this.targetItems = [];

    this.conversationSummary = "";
    this.conversationReasoning = "";
    // get id
    let lastId: string | null = null;
    try {
      lastId = this.items[0].id;
    } catch {
      console.log("no items");
    }
    this.getRecommendationAndCreateBulk(lastId);
  }

  public getSearch() {
    // cancel pending call
    clearTimeout(this.timerId);
    this.showSearchPhrase = this.searchPhrase;
    // delay new call 500ms
    if (this.searchPhrase !== "") {
      this.timerId = setTimeout(() => {
        this.getSearchRecommendationAndCreateBulk();
      }, 500);
    } else {
      this.getRecommendationAndCreateBulk();
    }
  }


  get chunkedConversation() {
     // Instead of creating a new array every time,
     // assign the data to a variable when the underlying item changes.
     // For example, if items is an array stored in Vuex,
     // modify your Vuex getter so it returns the same array instance
     // when nothing has really changed.
     return this.items && this.items[0]
      ? this.items[0].plain_text.trim().split("\n\n")
      : [];
  }

  public async getSearchRecommendationAndCreateBulk() {
    this.loadingItem = true;
    this.targetItems = [];
    this.error = "";
    // this.redoing = true;
    const allFilters = [...this.datasetFilters, ...this.modelFilters];

    const filterPerDataset: IFilterPerDataset = {
      dataset_id: this.dataset.id,
      store_id: this.dataset.store_id,
      model_filters: allFilters,
    };
    await dispatchGetSearchRecommendation(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      plainText: this.searchPhrase,
      filterPerDataset: filterPerDataset,
    })
      .then(() => {
        this.loadingItem = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          filteredItems.forEach((i) => {
            this.createItemUpdate(null, i.id, i.store_id);
          });
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
      });
  }

  public async getValidationRecommendationAndCreateBulk() {
    this.loadingItem = true;
    this.targetItems = [];
    this.error = "";
    // this.redoing = true;
    const allFilters = [...this.datasetFilters, ...this.modelFilters];

    const filterPerDataset: IFilterPerDataset = {
      dataset_id: this.dataset.id,
      store_id: this.dataset.store_id,
      model_filters: allFilters,
    };
    await dispatchGetValidationRecommendation(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      filterPerDataset: filterPerDataset,
    })
      .then(() => {
        this.loadingItem = false;
        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          if (this.recommendationType !== "single" && this.firstLabelContainer) {
            filteredItems.forEach((i) => {
              this.createItemUpdate(null, i.id, i.store_id);
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
      });
  }
  public async getConversationSummary(conversationId: string) {
    let decoder = new TextDecoder();
    let reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
    let localSummary = ""; // Use a local variable

    try {
      let datasetId = this.dataset === undefined ? null : this.dataset.id;
      reader = await api.streamConversationResponse(
        this.token,
        -1,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        datasetId!,
        conversationId,
        [],
        false,
      );

      const processText = async (result: ReadableStreamReadResult<Uint8Array>) => {
        if (result.done) {
          console.log("Stream complete");
          return;
        }

        localSummary += decoder.decode(result.value); // Update local variable
        this.conversationSummary = localSummary; // Update component property once

        try {
          if (reader) {
            const nextResult = await reader.read();
            await processText(nextResult);
          }
        } catch (error) {
          this.conversationSummaryError = "Error processing conversation stream";
          console.error("Error processing text:", error);
        }
      };

      const initialResult = await reader.read();
      await processText(initialResult);

      return localSummary; // Return the local variable
    } catch (error) {
      this.conversationSummaryError = "Error getting conversation summary";
      console.error("Error getting conversation summary:", error);
      throw error;
    } finally {
      if (reader) {
        try {
          await reader.cancel();
        } catch (e) {
          console.error("Error cancelling reader:", e);
        }
      }
    }
  }

  public async getConversationReasoning(conversationId: string) {
    this.conversationReasoning = "";
    this.loadingReasoning = true;

    try {
      let datasetId = this.dataset === undefined ? null : this.dataset.id;
      const response = await api.streamConversationReasoning(
        this.token,
        parseInt(this.$router.currentRoute.params.id, 10),
        "nemo", // NOTE: Hardcoded for now, as we use nemo model for reasoning
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        datasetId!,
        conversationId,
      );

      // Set the response data directly
      
      this.conversationReasoning = JSON.stringify(response.data);
      
    } catch (error) {
      this.conversationReasoningError = "Error getting conversation reasoning";
      console.error("Error getting conversation reasoning:", error);
      throw error;
    } finally {
      this.conversationReasoning = this.replacedConversationReasoning;
      this.loadingReasoning = false;
    }
  }

  get replacedConversationReasoning() {
    if (!this.conversationReasoning) {
      return "";
    }

    try {
      // Parse the JSON string
      const reasoningData = JSON.parse(this.conversationReasoning);

      // Add the showSourceDialog function to window so it's globally accessible
      (window as any).showSourceDialog = (numbers: string, text: string, conversationId: string) => 
        this.showSourceDialog(numbers, text, conversationId);

      // Format each reason with its source button
      const formattedReasons = reasoningData.reasons.map((item: any, index: number) => {
        const uniqueSortedNumbers = [...new Set(item.source_ids)].sort((a, b) => Number(a) - Number(b));
        const escapedText = item.reason.trim().replace(/'/g, "\\'");
        
        return `${index + 1}. ${item.reason}<button type="button" 
          class="v-btn v-btn--small v-btn--round theme--dark"
          onmouseenter="window.handleSourceHoverStart('${uniqueSortedNumbers.join(',')}', '${escapedText}', event)"
          onmouseleave="window.handleSourceHoverEnd()"
          onclick="showSourceDialog('${uniqueSortedNumbers.join(',')}', '${escapedText}', '${this.items[0].conversation_id}')">
          <div class="v-btn__content">View why</div>
        </button></br>`;
      }).join('\n\n');

      this.finalLabel = reasoningData.final_label
      // Add the final label if it exists
      const finalText = reasoningData.final_label ? 
        `\n\nFinal Label: ${reasoningData.final_label}` : '';

      return formattedReasons + finalText;

    } catch (error) {
      console.error("Error parsing conversation reasoning:", error);
      return "Error parsing reasoning data";
    }
  }

  public async getRecommendationAndCreateBulk(lastId: string | null = null) {
    this.delayCloseAlert();
    this.loadingItem = true;
    this.error = "";
    this.targetItems = [];
    this.redoing = false;

    const allFilters = [...this.datasetFilters, ...this.modelFilters];

    const filterPerDataset: IFilterPerDataset = {
      dataset_id: this.dataset.id,
      store_id: this.dataset.store_id,
      model_filters: allFilters,
    };

    await dispatchGetRecommendation(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      filterPerDataset: filterPerDataset,
      lastId,
    })
      .then(() => {
        this.loadingItem = false;
        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          if (this.recommendationType !== "single" && this.firstLabelContainer) {
            filteredItems.forEach((i) => {
              if (this.recommendation === "validation") {
                this.createItemUpdate(null, i.id, i.store_id);
              } else {
                console.log(this.getOrderedLabels(this.firstLabelContainer!.labels, i)[0].id);
                this.createItemUpdate(
                  this.getOrderedLabels(this.firstLabelContainer!.labels, i)[0].id,
                  i.id,
                  i.store_id,
                );
              }
            });
          }
        }
        if (this.model!.full_conversation) {
        this.getConversationSummary(this.items[0].conversation_id!);
        this.getConversationReasoning(this.items[0].conversation_id!);
        }
      })
      .catch((error) => {
        this.loadingItem = false;
        console.log("Error", error.response);
        console.log("I crash here");
        this.error = error.response;
      });
  }

  public getPredictionOrder(id, item) {
    const filteredItems = item.predictions.filter((prediction) => prediction.label === id);

    if (filteredItems.length > 0) {
      return filteredItems[0].prediction;
    }
    return 0;
  }

  public getOrderedLabels(labels, item) {
    const labelsToSort = labels.slice();

    labelsToSort.sort((a, b) => {
      return this.getPredictionOrder(b.id, item) - this.getPredictionOrder(a.id, item);
    });
    return labelsToSort;
  }

  public async getLastItem() {
    this.error = "";
    this.loadingItem = true;
    this.targetItems = [];
    this.redoing = true;
    this.lastItem = { plain_text: "" };
    await dispatchGetLastItem(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    })
      .then(() => {
        this.loadingItem = false;
        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          if (this.recommendationType !== "single" && this.firstLabelContainer) {
            filteredItems.forEach((i) => {
              if (this.recommendation === "validation") {
                this.createItemUpdate(null, i.id, i.store_id);
              } else {
                this.createItemUpdate(
                  this.getOrderedLabels(this.firstLabelContainer!.labels, i)[0].id,
                  i.id,
                  i.store_id,
                );
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
      });
  }

  public labelById(labelId) {
    return this.labels.filter((label) => label.id === labelId);
  }

  public async created() {
    await dispatchGetDatasets(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });

    await dispatchGetConnectedDatasets(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    });
    this.decodeFilters(this.$route.query.filters);
    this.getNewItem();
  }

  get encodeFilters() {
    const allFilters = [...this.datasetFilters, ...this.modelFilters];
    const filtersString = JSON.stringify(allFilters);
    return btoa(filtersString);
  }

  public async beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    next();
  }

  public decodeFilters(filterString: string | (string | null)[]) {
    try {
      if (filterString !== undefined && typeof filterString === "string") {
        const filtersString = atob(filterString);
        const filters = JSON.parse(filtersString);
        filters.forEach((filter) => {
          if (filter.filter_type !== "label") {
            this.datasetFilters.push(filter);
          } else {
            this.modelFilters.push(filter);
          }
        });
      }
    } catch (error) {
      console.log("Error while decoding filter:", error);
    }
  }

  

  public async mounted() {
    if (this.intervalId2 !== null) {
      clearInterval(this.intervalId2);
    }
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    // await this.getModelLanguages();
    await this.getMetrics();
    await this.getSystemColumns();
    this.createDatasetColumns();

    this.intervalId2 = window.setInterval(() => {
      const currentModelId = parseInt(this.$router.currentRoute.params.id, 10);
      if (!isNaN(currentModelId)) {
        console.log("From labeling view");
        this.getMetrics();
      }
    }, 15000);

    // Add the hover handlers to the window object
    (window as any).handleSourceHoverStart = (numbers: string, text: string, event: MouseEvent) => {
      const button = event.currentTarget as HTMLElement;
      const rect = button.getBoundingClientRect();

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const popupHeight = 600; // Height of the popup
      const popupWidth = 400;  // Width of the popup
      const minMargin = 20;    // Minimum margin from viewport edges
      
      // Calculate y position
      let yPos;

      if (rect.top > (popupHeight + minMargin)) {
        // Show popup above only if there's enough room
        yPos = rect.top;
      } else {
        // If not enough room above, show popup below

        yPos = rect.bottom + popupHeight / 4 + minMargin;
      }
      // After calculating position, add scroll offset
      yPos += window.scrollY;
      
      // Calculate x position
      let xPos = rect.left + window.scrollX;
      
      // If too close to right edge, shift left
      if (xPos + popupWidth + minMargin > viewportWidth) {
        xPos = xPos - popupWidth - 100;
      }
      // If too close to left edge, shift right 
      else if (xPos < minMargin) {
        xPos = xPos + 100;
      }
      // If in middle, default to shifting right
      else {
        xPos = xPos + 100;
      }
      
      this.hoverPosition = {
        x: xPos,
        y: yPos
      };

      this.hoveredSourceNumbers = numbers;
      this.hoveredSourceText = text;
      this.isHovering = true;
    };

    (window as any).handleSourceHoverEnd = () => {
      this.isHovering = false;
    };
  }

  public beforeRouteLeave(to: any, from: any, next: any) {
    if (this.intervalId2 !== null) {
      window.clearInterval(this.intervalId2);
    }
    next();
  }

  get colorOptions() {
    return [
      { name: "No color", value: null },
      ...this.colors.map((color) => ({ name: color, value: color })),
    ];
  }

  get notSelectedColors() {
    // filter and only show the colors that have not been selected from coloroptions
    return this.colorOptions.filter(
      (color) => !this.tempLabels.some((label) => label.color === color.value),
    );
  }

  public removeLabel(index: number) {
    this.dialogRemove = true;
    this.removeIndex = index;
  }

  public addLabelToDelete() {
    // add it to the remove list if we want to remove it, and it will be deleted when clicked on save
    if (!this.labelsToRemove.includes(this.labels[this.removeIndex].id)) {
      this.labelsToRemove.push(this.labels[this.removeIndex]);
      this.tempLabels.splice(this.removeIndex, 1); // this is just for show, if the page is refreshed it will be back
    }
    this.dialogRemove = false;
  }

  public removeExample(index: number, exampleIndex: number) {
    this.tempLabels[index].examples.splice(exampleIndex, 1);
  }

  public addNewExample(index: number) {
    // index is the label index
    this.tempLabels[index].examples.push("");
  }

  public addNewLabel() {
    this.tempLabels.push({ color: null, name: "", description: "", examples: [""] });
  }

  public async newColor(index, data) {
    this.tempLabels[index].color = data.value;
  }

  public pop() {
    // Loop to generate 30 particles at once
    for (let i = 0; i < 30; i++) {
      // We pass the mouse coordinates to the createParticle() function
      this.createParticle(window.innerWidth * (this.nextTraining / 100), 150, 200);
    }
  }
  public createParticle(x, y, width) {
    const particle = document.createElement("particle");
    document.body.appendChild(particle);
    // Calculate a random size from 5px to 25px
    const size = Math.floor(Math.random() * 10 + 5);
    particle.style.width = `${size}px`;
    particle.style.height = `${size}px`;
    // Generate a random color in a blue/purple palette
    particle.style.background = `hsl(${Math.random() * 100 + 60}, 90%, 60%)`;

    // Generate a random x & y destination within a distance of 75px from the mouse
    const destinationX = x + (Math.random() - 0.5) * 2 * 75;
    const destinationY = y + (Math.random() - 0.5) * 2 * 75;

    // Store the animation in a variable as we will need it later
    const animation = particle.animate(
      [
        {
          // Set the origin position of the particle
          // We offset the particle with half its size to center it around the mouse
          transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
          opacity: 1,
        },
        {
          // We define the final coordinates as the second keyframe
          transform: `translate(${destinationX}px, ${destinationY}px)`,
          opacity: 0,
        },
      ],
      {
        // Set a random duration from 500 to 1500ms
        duration: Math.random() * 750 + 1000,
        easing: "cubic-bezier(0, .9, .1, 1)",
        // Delay every particle with a random value of 200ms
        delay: Math.random() * 200,
      },
    );

    // When the animation is complete, remove the element from the DOM

    animation.onfinish = () => {
      particle.remove();
    };
  }

  public showSourceDialog(numbers: string, text: string, conversationId: string) {
    this.selectedSourceNumbers = numbers;
    this.selectedSourceText = text;
    this.selectedConversationId = conversationId; // Add this property to the class
    this.sourceDialog = true;
  }

  public sourceDialog: boolean = false;
  public selectedSourceNumbers: string = '';
  public selectedSourceText: string = '';
  public selectedConversationId: string = ''; // Add this property to the class

  get timelineChunks(): TimelineItem[] {
    // If no source numbers or conversation, return empty array.
    if (!this.selectedSourceNumbers || !this.chunkedConversation.length) {
      return [];
    }
    // Work with a copy of the conversation array so that we don’t modify reactive data.
    const conversation = this.chunkedConversation.slice();

    // Parse source numbers from selectedSourceNumbers.
    // Adjust the regex as needed (here we split by any whitespace or comma)
    const sourceNums = this.selectedSourceNumbers
      .split(/[\s,]+/)
      .map((n) => parseInt(n, 10))
      .filter((n) => !isNaN(n))
      .sort((a, b) => a - b);

    const timeline: TimelineItem[] = [];
    // A simple example: for each source number, add the context before and after along with the source entry.
    sourceNums.forEach((sourceNum) => {
      const sourceIndex = sourceNum - 1; // convert to 0-based index

      // If there’s a preceding context message (and you wish to include it)
      if (sourceIndex - 1 >= 0) {
        const contextText = conversation[sourceIndex - 1];
        const [prevRole, prevMessage] = this.parseMessage(contextText);
        timeline.push({
          role: prevRole,
          message: prevMessage,
          isSource: false,
          sourceNum: null,
          // Optionally add gap information.
          showGap: false
        });
      }

      // The source message.
      if (sourceIndex >= 0 && sourceIndex < conversation.length) {
        const sourceText = conversation[sourceIndex];
        const [srcRole, srcMessage] = this.parseMessage(sourceText);
        timeline.push({
          role: srcRole,
          message: srcMessage,
          isSource: true,
          sourceNum: sourceNum,
          showGap: false
        });
      }

      // If there’s a following context message (and you wish to include it)
      if (sourceIndex + 1 < conversation.length) {
        const contextText = conversation[sourceIndex + 1];
        const [nextRole, nextMessage] = this.parseMessage(contextText);
        timeline.push({
          role: nextRole,
          message: nextMessage,
          isSource: false,
          sourceNum: null,
          showGap: false
        });
      }
    });

    // Finally, sort the timeline by their order in the conversation. 
    // We create a copy (via slice()) to avoid mutating the timeline array.
    const sortedTimeline = timeline.slice().sort((a, b) => {
      // Find their index by matching the combination "role: message" in the conversation array.
      // (Assuming your conversation strings are in the format "caller: some text" or "agent: some text")
      const indexA = conversation.findIndex((text) => {
        const [role, message] = this.parseMessage(text);
        return role === a.role && message === a.message;
      });
      const indexB = conversation.findIndex((text) => {
        const [role, message] = this.parseMessage(text);
        return role === b.role && message === b.message;
      });
      return indexA - indexB;
    });

    return sortedTimeline;
  }

  // Add this method
  public parseMessage(text: string): ['caller' | 'agent', string] {
      const match = text.match(/^(caller|agent):\s*(.*)/i);
      if (!match) {
        return ['caller', text]; // Default fallback
      }
    return [match[1].toLowerCase() as 'caller' | 'agent', match[2].trim()];
  }

  public beforeDestroy() {
    // Clean up the window handlers when component is destroyed
    delete (window as any).handleSourceHoverStart;
    delete (window as any).handleSourceHoverEnd;
  }
}
